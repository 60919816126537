import { ref, computed } from "@vue/composition-api";

import { requestListTermsAndConditions, requestChangeActiveTermsAndConditions, requestAddNewTermsAndConditions } from "@/service/ListoService";

const tcList = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getTCList = computed(() => tcList.value);

export const fetchTermsConditions = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { termsAndConditions, pagination }
    } = await requestListTermsAndConditions(reqData);

    if (termsAndConditions) {
      tcList.value.splice(0, tcList.value.length);
      tcList.value.push(...termsAndConditions);
    } else {
      tcList.value.splice(0, tcList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};

export const saveNewTermsConditions = async (data) => {
  try {
    loading.value = true;
    await requestAddNewTermsAndConditions(data);
  } finally {
    loading.value = false;
  }
};

export const addNewItem = async (data) => {
  tcList.value.unshift(data);
};

export const cancelNewItem = (id) => {
  var index = tcList.value.findIndex((i) => i.id === id);
  if (index !== -1) {
    tcList.value.splice(index, 1);
  }
};

export const toggleTCActive = async (data) => {
  try {
    loading.value = true;
    await requestChangeActiveTermsAndConditions(data);
  } finally {
    loading.value = false;
  }
};
