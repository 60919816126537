<template>
  <b-card no-body title="Terms &amp; Conditions">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="900" :disabled="getLoading" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1">
          <b-button
            v-b-tooltip.hover
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            title="Add new item"
            @click="addNewTAC"
            :disabled="getLoading"
          >
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>

          <b-button v-b-tooltip.hover v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" title="Refresh data" class="btn-icon" @click="fetchTermsConditions">
            <b-spinner v-if="getLoading" small />
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getTCList" :current-page="currentPage">
        <template #cell(name)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
        </template>
        <template #cell(active)="data">
          <b-form-checkbox :checked="data.value" class="custom-control-success" name="active-button" @change="toggleActiveSync($event, data.item)" switch />
        </template>

        <template #cell(created)="data">
          {{ showDateInFormat(data.value) }}
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <template v-if="row.item.isNew">
            <b-card title="Add" style="max-width: 80%" class="mb-2" bg-variant="light-secondary" border-variant="secondary">
              <b-card-text>
                <b-form>
                  <b-form-group id="input-group-1" label-for="input-short-name" label-cols="2" label-align="right">
                    <template #label>
                      <b>Short name:</b>
                    </template>
                    <b-form-input id="input-short-name" maxlength="500" v-model="form.shortName" type="url" placeholder="Enter short name" required></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-2" label-for="input-tcversion" label-cols="2" label-align="right">
                    <template #label>
                      <b>Version:</b>
                    </template>
                    <b-form-input id="input-tcversion" maxlength="500" v-model="form.tcVersion" placeholder="Enter version" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-3" label-for="input-tcurl" label-cols="2" label-align="right">
                    <template #label>
                      <b>Link:</b>
                    </template>
                    <b-form-input id="input-tcurl" maxlength="500" v-model="form.tcUrl" type="url" placeholder="Enter url" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-4" label-cols="2" label-align="right">
                    <template #label>
                      <b>Summary:</b>
                    </template>
                    <b-form-textarea
                      id="input-summary"
                      maxlength="1000"
                      rows="5"
                      v-model="form.summary"
                      placeholder="Enter terms &amp; conditions summary"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Organization" label-cols="2" label-for="orgForItem" label-align="right">
                    <template #label>
                      <b>Organization:</b>
                    </template>
                    <b-form-select id="orgForItem" :value="form.defaultOrg" value-field="OrganizationId" text-field="name" v-model="form.orgObject" :options="getAllOrgs">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select an Organization --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>

                  <br />
                  <b-form-group id="input-toggles" label=" " label-cols="2" label-align="right">
                    <b-form-checkbox v-model="form.active">Active?</b-form-checkbox>
                  </b-form-group>
                  <br />
                  <b-button @click="saveNew" variant="primary">Submit</b-button>
                  <b-button @click="cancelSave(row.item)" variant="danger">Cancel</b-button>
                </b-form>
              </b-card-text>
            </b-card>
          </template>
          <template v-else>
            <br />
            <template v-if="row.item.url">
              <b>Link : </b>
              <b-link :href="row.item.url" target="_blank">{{ row.item.url.length > 50 ? "..." + row.item.newsLink.substring(row.item.url.length - 50) : row.item.url }}</b-link>
              <p />
            </template>
            <br />
            <b-card :title="'Version: ' + row.item.tcVersion" bg-variant="light-secondary" border-variant="secondary" tag="article" style="max-width: 80%" class="mb-2">
              <b-card-text>
                {{ row.item.text }}
              </b-card-text>
            </b-card>
          </template>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  getTCList,
  fetchTermsConditions,
  saveNewTermsConditions,
  toggleTCActive,
  filterBy,
  setPerPage,
  getLoading,
  getTotalRows,
  getPerPage,
  addNewItem,
  cancelNewItem
} from "@/views/listoconfig/termsConditionsRequests";
import { loadAllOrgs, getAllOrgs } from "@/views/listoconfig/blogRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, watch } from "@vue/composition-api";

import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "TermsAndConditions",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const DEFAULT_FORM = {
      shortName: "",
      tcVersion: "",
      tcUrl: "",
      summary: "",
      orgObject: null,
      defaultOrg: "",
      active: false,
      orgId: ""
    };

    const form = DEFAULT_FORM;
    let nextId = 99999;

    const fields = [
      "name",
      { key: "tcVersion", label: "Version" },
      { key: "url", label: "Link" },
      { key: "active", label: "Active?", thClass: "text-center", tdClass: "text-center" },
      {
        key: "organizationName",
        label: "Organization"
      },
      "created"
    ];

    const saveNew = async () => {
      let pd = {};
      pd.name = form.shortName;
      pd.tcVersion = form.tcVersion;
      pd.url = form.tcUrl;
      pd.text = form.text;
      pd.active = form.active;
      pd.organizationUid = form.organizationUid;
      if (form.orgObject) {
        pd.organizationUid = form.orgObject;
      }
      try {
        await saveNewTermsConditions(pd);
      } finally {
        form.shortName = "";
        form.tcVersion = "";
        form.url = "";
        form.text = "";
        form.active = false;
        form.organizationUid = "";
        form.orgObject = null;
        form.isNew = false;
      }
    };

    watch(filterBy, (newValue, oldValue) => {
      initalLoadTCs();
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const initalLoadTCs = async () => fetchTermsConditions({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadTCs();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await initalLoadTCs({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await initalLoadTCs({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await initalLoadTCs({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    const addNewTAC = () => {
      let newData = DEFAULT_FORM;
      newData.id = nextId++;
      newData.isNew = true;
      newData.orgObject = getAllOrgs.value ? getAllOrgs.value[0].OrganizationId : null;
      addNewItem(newData);
    };

    const cancelSave = (data) => {
      if (data.isNew) {
        cancelNewItem(data.id);
      }
    };

    const toggleActiveSync = async (newVal, item) => {
      let req = {};
      req.forTermsAndConditionsUid = item.termsConditionsUid;
      req.active = newVal;

      try {
        await toggleTCActive(req);
      } catch (err) {
        let respMsg = "";
        if (err.response) {
          respMsg = err.response.data && err.response.data.message ? err.response.data.message : err.response.data.error ? err.response.data.error : "Server error";
        }
        respMsg = respMsg === "" ? "Unknown Error" : respMsg;
        root.$bvToast.toast(`Message: ${respMsg}`, {
          title: `Updating active state for ${item.name} Failed`,
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          autoHideDelay: 5000
        });
        await initalLoadTCs();
      }
    };

    onMounted(async () => {
      filterBy.value = "";
      await loadAllOrgs();
    });

    return {
      getTCList,
      fetchTermsConditions,
      saveNewTermsConditions,
      toggleTCActive,
      filterBy,
      setPerPage,
      getLoading,
      getTotalRows,
      getPerPage,
      onPageChanged,
      perPageChanged,
      showDateInFormat,
      currentPage,
      pageOptions,
      fields,
      form,
      saveNew,
      cancelSave,
      getAllOrgs,
      addNewTAC,
      toggleActiveSync
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
.label {
  text-align: right;
}
</style>
